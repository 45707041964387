import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts.js'
import moment from 'moment'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import {
  getTimeTableStartEnd,
  createSpecialWorkTimeArray,
  createWorkTimeArray
} from '@/utils/time_table'
import { makePageSize } from '@/utils/print_utils'

export default {
  data() {
    return { mixinTimeInterval: 5 }
  },
  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getStaff: 'staffs/getDataById',
      getPatient: 'patients/getDataById',
      reservations: 'reservations/getData',
      reservationColumns: 'reservationColumns/getData',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId',
      specialOpenTimes: 'specialOpenTimes/getData'
    }),
    mixinSelectDate() {
      return this.$store.getters['timeTable/get'].date
    },
    mixinDayReservations() {
      return this.reservations.filter(
        v =>
          v.date === this.mixinSelectDate && v.cancelFlg === 0 && v.delFlg === 0
      )
    },
    mixinStartEndTime() {
      return getTimeTableStartEnd(
        this.mixinSelectDate,
        this.mixinDayReservations
      )
    },
    mixinStartTime() {
      const startTime = String(this.mixinStartEndTime.startTime).padStart(4, 0)
      return startTime.substring(0, 2) + '00'
    },
    mixinEndTime() {
      let endTime = String(this.mixinStartEndTime.endTime).padStart(4, 0)
      if (endTime.slice(2) !== '00') {
        const hour = Number(endTime.slice(0, 2)) + 1
        endTime = ('0000' + hour + '00').slice(-4)
      }
      return endTime
    },
    mixinWorkTimeArrays() {
      const specialOpenTime = this.specialOpenTimes.find(
        v => v.date === this.mixinSelectDate
      )
      return specialOpenTime
        ? createSpecialWorkTimeArray(specialOpenTime, this.mixinSelectDate)
        : createWorkTimeArray(this.mixinSelectDate)
    },
    mixinTimeSlots() {
      let timeSlots = []
      let slot = moment(this.mixinStartTime, 'HHmm')
      while (slot < moment(this.mixinEndTime, 'HHmm')) {
        timeSlots.push(moment(slot).format('HH:mm'))
        slot.add(this.mixinTimeInterval, 'minutes')
      }
      return timeSlots
    },
    mixinWorkTimeSlots() {
      let workTimeSlots = []
      this.mixinWorkTimeArrays.forEach(({ startTime, endTime }) => {
        let slot = moment(startTime, 'HHmm')
        while (slot < moment(endTime, 'HHmm')) {
          workTimeSlots.push(moment(slot).format('HH:mm'))
          slot.add(this.mixinTimeInterval, 'minutes')
        }
      })
      return workTimeSlots
    },
    mixinSlots() {
      return this.printOption.lunchTime === '表示'
        ? this.mixinTimeSlots
        : this.mixinWorkTimeSlots
    },
    mixinPageMargins() {
      return this.printOption.spaceType === 1
        ? [0, 0, 0, 0]
        : this.printOption.spaceType === 2
        ? [10, 10, 10, 10]
        : this.printOption.spaceType === 3
        ? [20, 20, 20, 20]
        : [30, 30, 30, 30]
    }
  },

  methods: {
    mixinOpenPdf() {
      const docDefinition = this.mixinMakeDocDefinition()
      const fonts = {
        NotoSansMono: {
          normal: 'NotoSansMonoCJKJPRegular.otf',
          bold: 'NotoSansMonoCJKJPRegular.otf',
          italics: 'NotoSansMonoCJKJPRegular.otf',
          bolditalics: 'NotoSansMonoCJKJPRegular.otf'
        }
      }
      createPdf(docDefinition, {}, fonts).open()
    },
    mixinMakeDocDefinition() {
      const reservationColumns = this.mixinMakeReservationColumns()
      const colNum = reservationColumns.length
      const columnsPerPage =
        colNum % 4 === 0 || colNum % 4 === 3 ? 4 : colNum % 4 === 1 ? 5 : 3
      let reservationColumnGroups = []
      while (reservationColumns.length > columnsPerPage) {
        reservationColumnGroups.push(
          reservationColumns.splice(0, columnsPerPage)
        )
      }
      reservationColumnGroups.push(reservationColumns)
      return {
        content: this.mixinMakePages(reservationColumnGroups),
        defaultStyle: { font: 'NotoSansMono' },
        pageSize: makePageSize(this.printOption.printSize),
        pageMargins: this.mixinPageMargins
      }
    },
    mixinMakeReservationColumns() {
      return _.cloneDeep(this.reservationColumns)
        .map(reservationColumn => {
          const staffReservationColumn = this.getStaffReservationColumn(
            reservationColumn.id
          )
          reservationColumn.showFlg = staffReservationColumn.showFlg
          reservationColumn.order = staffReservationColumn.order
          const reservations = _.cloneDeep(this.mixinDayReservations)
            .filter(v => v.reservationColumnId === reservationColumn.id)
            .map(reservation => {
              const owner = this.getOwner(reservation.ownerId)
              const patient = this.getPatient(reservation.patientId)
              const staff = this.getStaff(reservation.staffId)
              reservation.staffName =
                staff !== undefined
                  ? `${staff.lastName} ${staff.firstName}`
                  : ''
              reservation.ownerName =
                owner !== undefined
                  ? owner.lastName + owner.firstName
                  : reservation.ownerLastName + reservation.ownerFirstName
              reservation.patientName = patient?.name || ''
              return reservation
            })
          reservationColumn.reservations = reservations
          return reservationColumn
        })
        .filter(reservationColumn => reservationColumn.showFlg === 1)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
    },
    mixinMakePages(reservationColumnGroups) {
      let pages = []
      reservationColumnGroups.forEach((columnGroup, i) => {
        const bodyRows = this.mixinMakeBodyRows(columnGroup)
        // プリントサイズと表示時間に応じて２枚目のページに切り替え
        if (this.printOption.printSize === 'A4' && bodyRows.length > 120) {
          bodyRows[120].forEach(v => {
            v.pageBreak = 'before'
          })
        } else if (
          this.printOption.printSize === 'B5' &&
          bodyRows.length > 96
        ) {
          bodyRows[96].forEach(v => {
            v.pageBreak = 'before'
          })
        } else if (
          this.printOption.printSize === 'A5' &&
          bodyRows.length > 84
        ) {
          bodyRows[84].forEach(v => {
            v.pageBreak = 'before'
          })
        }
        pages.push({
          stack: this.mixinMakeReservationTable(columnGroup, bodyRows),
          pageBreak: i === 0 ? '' : 'before'
        })
      })
      return pages
    },
    mixinMakeBodyRows(reservationColumns) {
      return this.mixinSlots.map(v => {
        const fillColor = this.mixinWorkTimeSlots.includes(v) ? '' : '#7c7c7c'
        const border = [true, v.slice(2) === ':00', true, v.slice(2) === ':55']
        const HHmm = v.replace(/:/g, '')
        const rowArray = reservationColumns.map(reservationColumn => {
          const reservation = reservationColumn.reservations.find(
            v => v.startTime === HHmm
          )
          return reservation
            ? this.mixinMakeReservationCell(reservation)
            : { text: '', fillColor, border }
        })
        const timeColumnCell = {
          text: v.slice(2) === ':00' ? v + ' ~' : '',
          border,
          fillColor
        }
        return [timeColumnCell].concat(rowArray)
      })
    },
    mixinMakeReservationTable(reservationColumns, bodyRows) {
      const header = [
        { text: '', border: [false, false, false, false] }
      ].concat(
        reservationColumns.map(v => {
          return { text: v.name, border: [true, true, true, true] }
        })
      )
      return [
        {
          text: moment(this.mixinSelectDate).format('Y年M月D日（dd）'),
          style: { fontSize: 10, alignment: 'center' }
        },
        {
          style: { fontSize: 8.8, alignment: 'center' },
          table: {
            widths: [40].concat(new Array(reservationColumns.length).fill('*')),
            body: [header].concat(bodyRows)
          }
        }
      ]
    },
    mixinMakeReservationCell(reservation) {
      const startTime = moment(reservation.startTime, 'HHmm')
      const endTime = moment(reservation.endTime, 'HHmm')
      const diffMinutes = endTime.diff(startTime, 'minutes')
      const text = `飼主名: ${reservation.ownerName}\n患者名: ${reservation.patientName}\n診察内容: ${reservation.care}\n担当者: ${reservation.staffName}`
      return {
        text,
        maxHeight: diffMinutes,
        alignment: 'left',
        fillColor: '#fce1cc',
        fontSize: 5,
        rowSpan: diffMinutes / this.mixinTimeInterval
      }
    }
  }
}
