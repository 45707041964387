<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="print-popup" @keyup.esc.stop="cancel" tabindex="-1">
      <popup
        :title="'予約表の印刷'"
        :buttons="['閉じる', '印刷']"
        :contentStyle="{}"
        @cancel="cancel"
        @click-close-mark="cancel"
        @decision="mixinOpenPdf"
      >
        <template v-slot:content>
          <div class="body">
            <div class="left-body">
              <div class="left-body-title">
                印刷する予約列を選択
              </div>
              <div class="left-body-content">
                <reservation-column-slide-tab
                  class="slide-tab"
                  v-for="(datum, index) in bodyData"
                  v-bind="datum"
                  :key="datum.id"
                  :index="index"
                />
              </div>
            </div>
            <div class="right-body">
              <radio-button-form
                class="rest-time-form"
                v-bind="lunchTimeRadioButtonData"
                v-model="printOption.lunchTime"
                :title-styles="titleStyles"
              />
              <div class="print-size-form">
                <label>用紙サイズ</label>
                <div class="radio-buttons">
                  <base-radio-button
                    v-for="datum in printSizeRadioButtonData"
                    :key="datum.id"
                    :option="datum"
                    v-model="printOption.printSize"
                    data-test="print-size"
                  />
                </div>
              </div>
              <div class="space-type-form">
                <label>余白設定</label>
                <div class="radio-buttons">
                  <base-select-box
                    :selectData="spaceTypes"
                    v-model="printOption.spaceType"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import ReservationColumnSlideTab from '@/components/parts/organisms/ReservationColumnSlideTab'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import PrintReservationColumns from '@/components/mixins/PrintReservationColumns'
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'PrintPopup',
  components: {
    Popup,
    ReservationColumnSlideTab,
    RadioButtonForm,
    BaseRadioButton,
    BaseSelectBox,
    FocusTrap
  },
  mixins: [PrintReservationColumns],
  data() {
    return {
      bodyData: [],
      printOption: { lunchTime: '表示', printSize: 'A4', spaceType: 3 },
      lunchTimeRadioButtonData: {
        title: '予約受付時間外',
        radioButtonData: [
          { id: 5, eachValue: '表示', labelName: '表示する' },
          { id: 6, eachValue: '非表示', labelName: '表示しない' }
        ]
      },
      printSizeRadioButtonData: [
        { id: 7, eachValue: 'A4', labelName: 'A4' },
        { id: 8, eachValue: 'B5', labelName: 'B5' },
        { id: 9, eachValue: 'A5', labelName: 'A5' }
      ],
      titleStyles: { width: '134px', textAlign: 'left' },
      spaceTypes: [
        { id: 1, name: 'なし' },
        { id: 2, name: '狭い' },
        { id: 3, name: '標準' },
        { id: 4, name: '広い' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getReservationColumnGroup: 'reservationColumnGroups/getDataById',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId'
    }),
    reservationColumns() {
      return _.cloneDeep(this.$store.getters['reservationColumns/getData'])
    }
  },
  created() {
    this.bodyData = this.makeBodyData()
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    makeBodyData() {
      const usedReservationColumnGroupIds = this.reservationColumns
        .map(v => v.reservationColumnGroupId)
        .filter((x, i, self) => self.indexOf(x) === i)
      const usedReservationColumnGroups = usedReservationColumnGroupIds.map(i =>
        this.getReservationColumnGroup(i)
      )
      return usedReservationColumnGroups
        .map(v => {
          const sameGroupReservationColumns = this.reservationColumns.filter(
            x => x.reservationColumnGroupId === v.id
          )
          const groupOrder = this.getStaffReservationColumn(
            sameGroupReservationColumns[0].id
          ).order
          sameGroupReservationColumns.forEach(x => {
            const staffReservationColumn = this.getStaffReservationColumn(x.id)
            x.staffReservationColumnId = staffReservationColumn.id
            x.order = staffReservationColumn.order
          })
          sameGroupReservationColumns.sort((a, b) => {
            return a.order > b.order ? 1 : -1
          })
          return {
            groupOrder: groupOrder,
            reservationColumnGroupId: v.id,
            reservationColumnGroupName: v.name,
            reservationColumns: sameGroupReservationColumns
          }
        })
        .sort((a, b) => {
          return a.groupOrder > b.groupOrder ? 1 : -1
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.print-popup {
  position: absolute;
  top: 0;
  left: 0;
  .body {
    display: flex;
    width: 960px;
    min-height: 560px;
    > .left-body {
      box-sizing: border-box;
      width: 40%;
      height: 545px;
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      > .left-body-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        border-bottom: 1px solid #{$light-grey};
      }
      > .err-msg {
        color: #{$tomato};
      }
      > .left-body-content {
        width: 100%;
        height: 495px;
        font-size: 100px;
        overflow: auto;
        > .slide-tab {
          margin: 20px 30px;
        }
      }
    }
    > .right-body {
      box-sizing: border-box;
      width: 60%;
      height: 545px;
      border-bottom: 1px solid #{$light-grey};
      padding: 0 36px;
      font-size: 15px;
      > .open-time-form,
      .rest-time-form {
        margin-top: 30px;
      }
      > .print-size-form,
      .print-type-form {
        display: flex;
        margin-top: 30px;
        > label {
          width: 134px;
          margin-right: 20px;
          text-align: left;
          font-size: 15px;
        }
        > .radio-buttons {
          display: flex;
        }
      }
      > .space-type-form {
        margin-top: 25px;
        display: flex;
        > label {
          width: 134px;
          height: 33px;
          line-height: 33px;
          margin-right: 20px;
          text-align: left;
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .body {
    width: 760px;
    height: 495px;
    > .left-body {
      width: 30%;
      height: 495px;
      > .err-msg {
        font-size: 11px;
      }
      > .left-body-content {
        height: 440px;
      }
    }
    > .right-body {
      width: 70%;
      height: 495px;
    }
  }
}
</style>
